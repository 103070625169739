<template>
  <main class="kb-main" id="kb-helpdesk-editor">
    <!-- main-content -->
    <div class="main-content main-component">
      <!-- board-editor-container -->
      <div class="board-editor-container">
        <!-- editor-aside -->
        <div class="editor-aside">
          <div class="aside-inner">
            <!-- editor-aside > aside-header -->
            <div class="aside-header">
              <!-- kb-form-row -->
              <div class="kb-form-row">
                <input type="text" v-model="inqItem.inqTitle" class="kb-form-control" name="" id="" placeholder="제목 입력">
              </div>
              <!-- kb-form-row -->
              <div class="kb-form-row">
                <div class="kb-form-dropdown" :class="{'is-active' : isCateToggle}">
                  <button class="kb-form-dropdown-btn" @click="isCateToggle = !isCateToggle"><strong class="text">{{ inqItem.inqTyNm }}</strong><i class="icon-arrow"></i></button>
                  <div class="kb-form-dropdown-options">
                    <div class="dropdown-option">
                      <ul class="dropdown-option-list">
                        <li v-for="(item, idx) in inqTyCdDcds" :key="idx" class="dropdown-option-item">
                          <a href="javascript:" class="dropdown-option-link" @click="changeInqCd(item)"><span class="dropdown-option-text">{{item.cdNm}}</span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- editor-aside > aside-files -->
            <div class="aside-files">
              <ul class="file-list">
                <li class="file-item" v-for="(item, idx) in files.files" :key="idx">
                  <div class="file">
                    <p class="filename" title="">
                      <span class="filename-base">{{item.fileNm}}</span><span class="filename-extension"></span>
                      <button type="button" @click="removeFile(item)" style="margin-left: 10px;"><i class="icon-x"></i></button>
                    </p>
                    <p class="filesize">{{convertToStorageBytes(item.fileSz)}}</p>
                  </div>
                </li>
              </ul>
              <div class="file-upload">
                <div class="kb-form-file">
                  <label for="inp-file" class="kb-form-file-btn kb-btn kb-btn-secondary"><span class="text">파일첨부</span></label>
                  <input type="file" ref="uploadFiles" @change="addFile" class="kb-form-file-input" id="inp-file" style="cursor: pointer;">
                </div>
                <p class="kb-form-file-tip">파일첨부는 최대 1개까지 가능합니다.</p>
              </div>
            </div>
            <!-- editor-aside > aside-actions -->
            <div class="aside-actions">
              <button class="kb-btn kb-btn-wide kb-btn-secondary" @click="goBack"><span class="text">취소</span></button>
              <button class="kb-btn kb-btn-wide kb-btn-silver" @click="clickSave"><span class="text">저장</span></button>
            </div>
          </div>
        </div>
        <!-- editor-contents -->
        <div class="editor-contents">
          <!-- editor-contents > content-body -->
          <div class="content-body">
            <div ref="pageEditor"></div>
          </div>
          <!-- editor-contents > content-footer -->
          <div class="content-footer">
            <div class="footer-column footer-column-right">
<!--              <span class="footer-byte" id="nowByte">0 / 3000</span>-->
            </div>
          </div>
        </div>
      </div>
      <!-- //board-editor-container -->
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import {MUT_TOGGLE_CONCENTRATION_MODE} from '@/store/modules/hrdcloud/hrdcloud';
import {useStore} from 'vuex';
import {onBeforeMount, onBeforeUnmount, onMounted, ref, computed} from 'vue';
import ClassicEditor from '@/assets/js/editor/custom-ckeditor';
import {helpDeskEditorConfig} from '@/assets/js/editor/editor-config';
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_INSERT_QA} from "@/store/modules/common/common-index";
import {
  convertToStorageBytes,
  getItem,
  isSuccess,
  isSuccessFailAlert,
  isUploadSuccess,
  lengthCheck
} from "@/assets/js/util";
import {useRoute} from "vue-router";
import {goBack} from '@/assets/js/modules/common/common';
import {insertFileAtch} from "@/assets/js/modules/hrd/hrd-common";
import {
  ACT_DELETE_HELP_QNA,
  ACT_GET_HELP_FILE_ATCH_LIST,
  ACT_GET_HELP_QNA,
  ACT_UPDATE_HELP_QNA
} from "@/store/modules/help/help";
import UploadAdapter from "@/assets/js/editor/upload-adapter";
import {checkExtension} from "@/assets/js/file-util";
import {serverUploadError} from "@/assets/js/modules/error/error-message";

export default {
  name: 'HelpQnaWrite',
  setup() {
    const store = useStore();
    const route = useRoute();
    const inqItem = ref({
      comInqSn: route.query.comInqSn > 0 ? route.query.comInqSn : 0,
      // tblNm: '',
      // tblSn: 0,
      inqTyCdDcd: '',
      inqTyNm: '카테고리 선택',
      inqTitle: '',
      inqCn: '',
      readYn: '',
      ansYn: '',
      ansCn: '',
      stt: '00',
    })

    const isCateToggle = ref(false);
    const pageEditor = ref(null);
    const {showMessage} = useAlert();
    const inqTyCdDcds = computed(() =>
        store.state.code.codes.filter(code => code.cd.substring(4) !== '000' && code.cd.indexOf('2091') === 0 && code.cd.indexOf('209101') !== 0 ));
    let $_instance = null;
    const isLoading = ref(false);

    const uploadFiles = ref(null);
    const files = ref({files: [], removed: [], binaries: []});
    // const saveFiles = ref({ files: [], removed: [], binaries: [] });

    const addFile = () => {
      let checkType = ['jpg' ,'jpeg' ,'png' ,'webp' ,'bmp'];
      if (uploadFiles.value && uploadFiles.value.files && uploadFiles.value.files.length > 0) {
        if(checkExtension(uploadFiles.value.files, checkType)){
          showMessage(`확장자가 ${checkType.join(', ')} 인 파일만 등록 가능합니다.`);
          return;
        }
        if((files.value.files.length + uploadFiles.value.files.length) > 1) {
          showMessage(`첨부파일은 최대 1개까지 등록 가능합니다.`);
          uploadFiles.value.value = null;
          uploadFiles.value.files = null;
          return;
        }

        for(let i=0; i < uploadFiles.value.files.length; i++){
          uploadFiles.value.files[i].fileNm = uploadFiles.value.files[i].name
          uploadFiles.value.files[i].fileSz = uploadFiles.value.files[i].size
          files.value.files.push(uploadFiles.value.files[i]);
        }
      }

    }

    onMounted(() => {
      uploadFiles.value = null;
      setPageEditor();
      if(inqItem.value.comInqSn > 0) {
        getQa();
      }
    })

    onBeforeMount(() => {
      store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`, { 'mode' : 'fill'});
      if ( $_instance ) {
        $_instance.destroy();
        $_instance = null;
      }
    });

    onBeforeUnmount(() => {
      store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`, { 'mode' : ''});
    });

    const changeInqCd = (item) => {
      inqItem.value.inqTyNm = item.cdNm
      inqItem.value.inqTyCdDcd = item.cd;
      isCateToggle.value = false;
    }

    // 에디터 세팅
    const setPageEditor = () => {
      ClassicEditor.create( pageEditor.value, helpDeskEditorConfig )
          .then(editor => {
            editor.isReadOnly = false;
            // editor.disableReadOnlyMode( 'pageEditor' );
            $_instance = editor;

            editor.plugins.get('FileRepository').createUploadAdapter = ( loader ) => {
              return new UploadAdapter( loader );
            };
          })
          .catch( error => {
            console.error( error );
          });
    };

    const setInqCn = () => {
      if($_instance){
        $_instance.setData(inqItem.value.inqCn)
      }
    };

    const getQa = () => {
      store.dispatch(`help/${ACT_GET_HELP_QNA}`, inqItem.value.comInqSn
      ).then(res => {
        if (lengthCheck(res)) {
          let item = getItem(res);
          inqItem.value.comInqSn = item.comInqSn
          inqItem.value.inqTyCdDcd = item.inqTyCdDcd
          inqItem.value.inqTyNm = item.inqTyNm
          inqItem.value.inqTitle = item.inqTitle
          inqItem.value.inqCn = item.inqCn
          inqItem.value.readYn = item.readYn
          inqItem.value.ansYn = item.ansYn
          inqItem.value.ansCn = item.ansCn
          setInqCn();
          getHelpQaFileAtchList();
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const getHelpQaFileAtchList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FILE_ATCH_LIST}`, {
        comInqSn: inqItem.value.comInqSn
      }).then(res => {
        if (lengthCheck(res)) {
          let item = getItem(res);
          files.value.files.push(item)
        } else {
          files.value.files = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const clickSave = () => {
      if(isLoading.value) return;
      // isLoading.value = true;
      inqItem.value.inqCn = $_instance.getData();

      if(inqItem.value.inqTitle === ''){
        showMessage('제목을 입력하세요');
        return false;
      }
      if(inqItem.value.inqCn === ''){
        showMessage('내용을 입력하세요');
        return false;
      }
      if(inqItem.value.inqTyCdDcd === ''){
        showMessage('카테고리를 선택하세요');
        return false;
      }

      if(route.query.comInqSn > 0){ // 수정
        store.dispatch(`help/${ACT_UPDATE_HELP_QNA}`, {
          comInqSn: inqItem.value.comInqSn,
          params: {
            ...inqItem.value,
            deleteFileAtchs: files.value.removed
          }
        }).then(async (res) => {
          if (isSuccessFailAlert(res)) {
            if (files.value.files.length > 0) {
              let fileRes = await insertFileAtch({comInqSn: inqItem.value.comInqSn}, files.value.files);
              if (isUploadSuccess(fileRes)) {
                isLoading.value = false;
                showMessage({
                  text: "QnA가 수정되었습니다.",
                  callback: goBack
                })
              } else {
                showMessage(serverUploadError);
              }
            } else {
              isLoading.value = false;
              showMessage({
                text: "QnA가 수정되었습니다.",
                callback: goBack
              })
            }
          }
        }).catch((err) => {
          console.error(err);
        })
      }else{ // 저장
        store.dispatch(`common/${ACT_INSERT_QA}`, inqItem.value)
            .then(async (res) => {
              if (isSuccess(res)) {
                if(files.value.files.length > 0){
                  let fileRes = await insertFileAtch({comInqSn: res.comInqSn}, files.value.files);
                  if (isUploadSuccess(fileRes)) {
                    isLoading.value = false;
                    showMessage({
                      text: "QnA가 등록되었습니다.",
                      callback: goBack
                    })
                  } else {
                    // 첨부파일 저장 실패 시 게시글 미등록 처리 (삭제)
                    await store.dispatch(`help/${ACT_DELETE_HELP_QNA}`, res.comInqSn);
                    showMessage(serverUploadError);
                  }
                }else{
                  isLoading.value = false;
                  showMessage({
                    text: "QnA가 등록되었습니다.",
                    callback: goBack
                  })
                }
              }else{
                showMessage('등록 실패했습니다.')
              }
            }).catch((err) => {
          showMessage("QnA 등록 실패<br> 관리자에게 문의해주세요");
          console.error(err)
        });
      }
    }

    const removeFile = (item) => {
      files.value.files = [];
      files.value.removed.push({'comFileAtchSn': item.comFileAtchSn})
    }

    return{
      inqItem,
      inqTyCdDcds,
      isCateToggle,
      pageEditor,
      uploadFiles,
      files,
      isLoading,
      addFile,
      changeInqCd,
      setInqCn,
      clickSave,
      goBack,
      convertToStorageBytes,
      removeFile,
    }
  }
};
</script>
<style type="text/css">
.content-body .ck-editor .ck-content p{
  font: unset;
}
</style>